import { useNotebook, useSetSelectedNote } from "../hook";

export const useSelectMainNote: () => (config?: {
  notebook?: any;
}) => void = () => {
  const [notebookState, setNotebook] = useNotebook();
  const setSelectedNote = useSetSelectedNote();

  return (config = {}) => {
    const { notebook: notebookParam } = config;
    const notebook = notebookParam ?? notebookState;
    const page = notebook.pages[0];
    setSelectedNote(page);
  };
};
