import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createConnectCard,
  createConversation,
  deleteTable,
  getApps,
  getConnectorTables,
  getConnectorTypes,
  getConnectors,
  getNotebook,
  getTable,
  replyConversation as replyConversationApi,
} from "./api";
import { useFetchPushConnectors } from "./hook/pushConnector";
import { getTableIds } from "./notebook";
import {
  ActionType,
  AppDispatch,
  ConnectCard,
  Connector,
  Message,
  MessageRole,
  PageContentType,
  RootState,
  SelectedPage,
} from "./reducer";
import { useSelectMainNote } from "./hook/note";

const R = require("ramda");

export const useNotebook: () => [any, (notebook: any) => void] = () => {
  const notebook = useSelector((state: RootState) => state.main.notebook);
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedNote = useSetSelectedNote();
  const addTables = useAddTables();
  const [appId, setAppId] = useAppId();
  // const deleteNoteTable = useDeleteNoteTable();

  const fetchTables: (config: {
    tableIds: string[];
    appId: string;
  }) => Promise<any[]> = ({ appId, tableIds }) => {
    return Promise.all(
      R.map((tableId: string) => getTable({ appId, tableId }), tableIds)
    );
  };

  const setNotebook = (notebook: any) => {
    dispatch({ type: ActionType.SET_NOTEBOOK, notebook });
    const tableIds = getTableIds(notebook);
    fetchTables({ tableIds, appId: appId! }).then((tables) => {
      addTables(tables);
    });
  };

  return [notebook, setNotebook];
};

export const useAppId: () => [
  string | undefined,
  (notebook: string) => void
] = () => {
  const appId = useSelector((state: RootState) => state.main.appId);
  const dispatch = useDispatch<AppDispatch>();
  const setNotebook = (appId: string) =>
    dispatch({ type: ActionType.SET_APP_ID, appId });
  return [appId, setNotebook];
};

export const useSelectedPage: () => [
  SelectedPage,
  (selectedPage: SelectedPage) => void
] = () => {
  const selectedPage = useSelector(
    (state: RootState) => state.main.selectedPage
  );
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedPage = (selectedPage: SelectedPage) =>
    dispatch({ type: ActionType.SET_SELECTED_PAGE, selectedPage });
  return [selectedPage, setSelectedPage];
};

export const useChatId: () => [
  string | undefined,
  (chatId: string) => void
] = () => {
  const chatId = useSelector((state: RootState) => state.main.chatId);
  const dispatch = useDispatch<AppDispatch>();
  const setChatId = (chatId: string) =>
    dispatch({ type: ActionType.SET_CHAT_ID, chatId });
  return [chatId, setChatId];
};

export const useChatDrawerOpen: () => [
  boolean,
  (chatDrawerOpen: boolean) => void
] = () => {
  const chatDrawerOpen = useSelector(
    (state: RootState) => state.main.chatDrawerOpen
  );
  const dispatch = useDispatch<AppDispatch>();
  const setChatDrawerOpen = (chatDrawerOpen: boolean) =>
    dispatch({ type: ActionType.SET_CHAT_DRAWER_OPEN, chatDrawerOpen });
  return [chatDrawerOpen, setChatDrawerOpen];
};

export const useToggleChatDrawerOpen: () => () => void = () => {
  const chatDrawerOpen = useSelector(
    (state: RootState) => state.main.chatDrawerOpen
  );
  const dispatch = useDispatch<AppDispatch>();
  const toggleChatDrawerOpen = () =>
    dispatch({
      type: ActionType.SET_CHAT_DRAWER_OPEN,
      chatDrawerOpen: !chatDrawerOpen,
    });
  return toggleChatDrawerOpen;
};

export const useSideNavOpen: () => [
  boolean,
  (sideNavOpen: boolean) => void
] = () => {
  const sideNavOpen = useSelector((state: RootState) => state.main.sideNavOpen);
  const dispatch = useDispatch<AppDispatch>();
  const setSideNavOpen = (sideNavOpen: boolean) =>
    dispatch({ type: ActionType.SET_SIDE_NAV_OPEN, sideNavOpen });
  return [sideNavOpen, setSideNavOpen];
};

export const useToggleSideNavOpen: () => () => void = () => {
  const sideNavOpen = useSelector((state: RootState) => state.main.sideNavOpen);
  const dispatch = useDispatch<AppDispatch>();
  const toggleSideNavOpen = () =>
    dispatch({
      type: ActionType.SET_SIDE_NAV_OPEN,
      sideNavOpen: !sideNavOpen,
    });
  return toggleSideNavOpen;
};

export const useMessages: () => [
  Message[],
  (messages: Message[]) => void
] = () => {
  const messages = useSelector((state: RootState) => state.main.messages);
  const dispatch = useDispatch<AppDispatch>();
  const setMessages = (messages: Message[]) =>
    dispatch({ type: ActionType.SET_MESSAGES, messages });
  return [messages, setMessages];
};

export const useAddMessage: () => (message: Message) => void = () => {
  const dispatch = useDispatch<AppDispatch>();
  const addMessage = (message: Message) =>
    dispatch({ type: ActionType.ADD_MESSAGE, message });
  return addMessage;
};

export const useConnectors: () => [
  Connector[],
  (connectors: Connector[]) => void
] = () => {
  const connectors = useSelector((state: RootState) => state.main.connectors);
  const dispatch = useDispatch<AppDispatch>();
  const setConnectors = (connectors: Connector[]) =>
    dispatch({ type: ActionType.SET_CONNECTORS, connectors });
  return [connectors, setConnectors];
};

export const useConnectorTypes: () => [
  any[],
  (connectorTypes: any[]) => void
] = () => {
  const connectorTypes = useSelector(
    (state: RootState) => state.main.connectorTypes
  );
  const dispatch = useDispatch<AppDispatch>();
  const setConnectorTypes = (connectorTypes: any[]) =>
    dispatch({ type: ActionType.SET_CONNECTOR_TYPES, connectorTypes });
  return [connectorTypes, setConnectorTypes];
};

export const useSelectedConnectorType: () => [
  any,
  (connectorTypes: any) => void
] = () => {
  const selectedConnectorType = useSelector(
    (state: RootState) => state.main.selectedConnectorType
  );
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const dispatch = useDispatch<AppDispatch>();
  const setSelectedConnectorType = (selectedConnectorType: any) => {
    dispatch({
      type: ActionType.SET_SELECTED_CONNECTOR_TYPE,
      selectedConnectorType,
    });
    setSelectedPage({ contentType: PageContentType.ADD_CONNECT_CARD });
  };
  return [selectedConnectorType, setSelectedConnectorType];
};

export const useSelectedConnectCard: () => [
  ConnectCard | undefined,
  (selectedConnectCard: ConnectCard) => void
] = () => {
  const selectedConnectCard = useSelector(
    (state: RootState) => state.main.selectedConnectCard
  );
  const dispatch = useDispatch();
  const setSelectedConnectCard = (selectedConnectCard: ConnectCard) => {
    dispatch({
      type: ActionType.SET_SELECTED_CONNECT_CARD,
      selectedConnectCard,
    });
  };
  return [selectedConnectCard, setSelectedConnectCard];
};

export const useFetchSelectedConnectCard: () => any = () => {
  const [selectedConnectorType, setSelectedConnectorType] =
    useSelectedConnectorType();
  const [selectedConnectCard, setSelectedConnectCard] =
    useSelectedConnectCard();
  const selectedConnectorTypeId = selectedConnectorType?.id;
  const selectedConnectCardId = selectedConnectCard?.id;
  const [appId, setAppId] = useAppId();

  useEffect(() => {
    if (!selectedConnectCardId && selectedConnectorTypeId && appId) {
      createConnectCard({
        appId: appId!,
        schema: selectedConnectorTypeId,
      }).then((response) => {
        const card = response["connect-card"];
        setSelectedConnectCard(card);
      });
    }
  }, [selectedConnectCardId, selectedConnectorTypeId, appId]);
};

export const useSetSelectedNote: () => (page: any) => void = () => {
  const [_selectedPage, setSelectedPage] = useSelectedPage();
  const setSelectedNote = (page: any) =>
    setSelectedPage({
      contentType: PageContentType.NOTEBOOK,
      page,
    });
  return setSelectedNote;
};

export const useNoWorkspaces: () => [
  boolean,
  (noWorkspaces: boolean) => void
] = () => {
  const noWorkspaces = useSelector(
    (state: RootState) => state.main.noWorkspaces ?? false
  );
  const dispatch = useDispatch();
  const setNoWorkspaces = (noWorkspaces: boolean) => {
    dispatch({
      type: ActionType.SET_NO_WORKSPACES,
      noWorkspaces,
    });
  };
  return [noWorkspaces, setNoWorkspaces];
};

export const useFetchAppId: () => void = () => {
  const [appId, setAppId] = useAppId();
  const [authToken, setAuthToken] = useAuthToken();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [noWorkspaces, setNoWorkspaces] = useNoWorkspaces();

  const setAppFromResponse = (result: any) => {
    const appId = result.apps?.[0]?.id;
    appId ? setAppId(appId) : setNoWorkspaces(true);
  };

  useEffect(() => {
    if (!authToken) return;

    setSelectedPage({ contentType: PageContentType.LOADING_ACCOUNT });
    getApps({ authToken }).then(setAppFromResponse);
  }, [authToken]);
};

export const useAddTables: () => (tables: any[]) => void = () => {
  const dispatch = useDispatch();
  const addTables = (tables: any[]) => {
    dispatch({ type: ActionType.ADD_TABLES, tables });
  };
  return addTables;
};

export const useAssociateConnectorTables: () => (
  connectorTables: any
) => void = () => {
  const setSelectedPullConnector = useSetSelectedPullConnector();
  const dispatch = useDispatch();
  const associateConnectorTables = (connectorTables: any) => {
    dispatch({ type: ActionType.ASSOCIATE_CONNECTOR_TABLES, connectorTables });
    // const connectorId = Object.keys(connectorTables)[0];
    // const tableId = connectorTables[connectorId][0]["file-id"];
    // setSelectedPullConnector({ connectorId, tableId });
  };
  return associateConnectorTables;
};

export const useHandleConnectorsResponse: () => (
  connectorsResponse: any
) => void = () => {
  const [appId, setAppId] = useAppId();
  const associateConnectorTables = useAssociateConnectorTables();
  const addTables = useAddTables();
  const [connectors, setConnectors] = useConnectors();

  const fetchConnectorTables: (config: {
    connectorIds: string[];
    appId: string;
  }) => Promise<any[]> = ({ appId, connectorIds }) => {
    return Promise.all(
      R.map(
        (connectorId: string) => getConnectorTables({ appId, connectorId }),
        connectorIds
      )
    );
  };

  const handleConnectorsResponse = (connectorsResponse: any) => {
    const cards = R.sortBy(
      R.path(["card", "schema"]),
      R.prop("cards", connectorsResponse)
    );
    const connectorIds = R.map(R.prop("connection-id"), cards);
    fetchConnectorTables({ appId: appId!, connectorIds }).then((responses) => {
      const connectorEntries = R.map((response: any) => {
        const conId = response["connector-id"];
        const tables = response.tables;
        return [conId, tables];
      }, responses);
      const connectorData = Object.fromEntries(connectorEntries);
      associateConnectorTables(connectorData);
      const tables = R.chain((response: any) => {
        return R.map((table: any) => {
          return R.assoc("table-id", table["file-id"], table);
        }, response.tables);
      }, responses);
      addTables(tables);
    });
    setConnectors(cards);
  };
  return handleConnectorsResponse;
};

export const useHandleCreateConversation: () => (
  createConversationResponse: any
) => void = () => {
  const [chatId, setChatId] = useChatId();
  const replyConversation = useReplyConversation();

  const handleCreateConversation = (createConversationResponse: any) => {
    const chatId = createConversationResponse["chat-id"];
    setChatId(chatId);
    // replyConversation({
    //   content:
    //     "Give me the full names from the transformed go high level contacts table.",
    //   chatId,
    // });
  };
  return handleCreateConversation;
};

export const useFetchAppData: (appId?: string) => void = (appId) => {
  const [notebook, setNotebook] = useNotebook();
  const [chatId, setChatId] = useChatId();
  const [connectors, setConnectors] = useConnectors();
  const [connectorTypes, setConnectorTypes] = useConnectorTypes();
  const handleConnectorsResponse = useHandleConnectorsResponse();
  const handleCreateConversation = useHandleCreateConversation();
  const fetchPushConnectors = useFetchPushConnectors();
  const selectMainNote = useSelectMainNote();
  const [sideNav, setSideNavOpen] = useSideNavOpen();

  useEffect(() => {
    if (!appId) return;
    getNotebook({ appId }).then((notebook) => {
      setNotebook(notebook);
      selectMainNote({ notebook });
      setSideNavOpen(true);
    });
    getConnectors({ appId }).then(handleConnectorsResponse);
    createConversation({ appId }).then(handleCreateConversation);
    getConnectorTypes().then(setConnectorTypes);
    fetchPushConnectors();
  }, [appId]);
};

export const useNotebookTableIds: () => string[] = () => {
  const [notebook, setNotebook] = useNotebook();
  const tableIds = R.chain(
    R.compose(R.map(R.prop("table-id")), R.path(["page-data", "blocks"])),
    notebook?.pages ?? []
  );
  return tableIds;
};

export const useFetchApp: () => void = () => {
  const [appId, setAppId] = useAppId();
  useFetchAppId();
  useFetchAppData(appId);
};

export const usePageIds: () => any = () => {
  const [notebook, setNotebook] = useNotebook();
  const pages = R.sortBy(R.prop("page-id") as any, notebook?.pages ?? []);
  return pages;
};

export const useTable: (tableId?: string) => any | undefined = (tableId) => {
  const table = useSelector(
    (state: RootState) => tableId && state.main.tables?.[tableId]
  );
  return table;
};

export const useConnectorTables: (connectorId: string) => any[] = (
  connectorId
) => {
  const tables = useSelector(
    (state: RootState) => state.main.connectorTables?.[connectorId] ?? []
  );
  return tables;
};

export const useSetSelectedPullConnector: () => (config: {
  connectorId: string;
  tableId: string;
}) => void = () => {
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const setSelectedPullConnector: (config: {
    connectorId: string;
    tableId: string;
  }) => void = ({ connectorId, tableId }) => {
    setSelectedPage({
      contentType: PageContentType.PULL_CONNECTOR_TABLE,
      page: { connectorId, tableId },
    });
  };
  return setSelectedPullConnector;
};

export const useReplyConversation: () => (config: {
  content: string;
  chatId?: string;
}) => void = () => {
  const [chatIdFromState, setChatId] = useChatId();
  const [appId, setAppId] = useAppId();
  const addMessage = useAddMessage();
  const [notebook, setNotebook] = useNotebook();
  const [chatLoading, setChatLoading] = useChatLoading();
  const replyConversation: (config: {
    content: string;
    chatId?: string;
  }) => void = ({ content, chatId: chatIdFromConfig }) => {
    const chatId = chatIdFromConfig || chatIdFromState;
    addMessage({ content, role: MessageRole.USER });
    setChatLoading(true);
    replyConversationApi({ content, chatId: chatId! }).then((replyResponse) => {
      const reply = replyResponse.reply;
      addMessage({ content: reply, role: MessageRole.ASSISTANT });
      setChatLoading(false);
      getNotebook({ appId: appId! }).then(setNotebook);
    });
  };
  return replyConversation;
};

export const useChatLoading: () => [
  boolean,
  (chatLoading: boolean) => void
] = () => {
  const chatLoading = useSelector((state: RootState) => state.main.chatLoading);
  const dispatch = useDispatch();
  const setChatLoading = (chatLoading: boolean) => {
    dispatch({ type: ActionType.SET_CHAT_LOADING, chatLoading });
  };
  return [chatLoading, setChatLoading];
};

export const useDeleteNoteTable: () => (config: {
  tableId: string;
}) => void = () => {
  const [notebook, setNotebook] = useNotebook();
  const [appId, setAppId] = useAppId();
  const deleteNoteTable: (config: { tableId: string }) => void = ({
    tableId,
  }) => {
    deleteTable({ tableId }).then((response) => {
      getNotebook({ appId: appId! }).then(setNotebook);
    });
  };
  return deleteNoteTable;
};

export const useAuthToken: () => [
  string | undefined,
  (authToken: string) => void
] = () => {
  const authToken = useSelector((state: RootState) => state.main.authToken);
  const dispatch = useDispatch();
  const setAuthToken = (authToken: string) => {
    dispatch({ type: ActionType.SET_AUTH_TOKEN, authToken });
  };
  return [authToken, setAuthToken];
};

export const useFetchAuthToken: () => void = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [authToken, setAuthToken] = useAuthToken();
  useEffect(() => {
    if (!isAuthenticated) return;
    getAccessTokenSilently().then(setAuthToken);
  }, [isAuthenticated]);
};

export const useInitialPageLoaded: () => [
  boolean,
  (initialPageLoaded: boolean) => void
] = () => {
  const initialPageLoaded = useSelector(
    (state: RootState) => state.main.initialPageLoaded ?? false
  );
  const dispatch = useDispatch();
  const setInitialPageLoaded = (initialPageLoaded: boolean) => {
    dispatch({ type: ActionType.SET_INITIAL_PAGE_LOADING, initialPageLoaded });
  };
  return [initialPageLoaded, setInitialPageLoaded];
};

export const useSetInitialPage: () => void = () => {
  const { isAuthenticated } = useAuth0();
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [initialPageLoaded, setInitialPageLoaded] = useInitialPageLoaded();

  useEffect(() => {
    setInitialPageLoaded(true);
    if (isAuthenticated) {
      setSelectedPage({ contentType: PageContentType.LOADING_ACCOUNT });
    } else {
      setSelectedPage({ contentType: PageContentType.NOT_LOGGED_IN });
    }
  }, [initialPageLoaded]);
};
