import {
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import {
  useConnectorTables,
  useConnectors,
  useNotebook,
  useSelectedPage,
  useSetSelectedNote,
  useSetSelectedPullConnector,
  useSideNavOpen,
  useToggleSideNavOpen,
} from "../hook";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import {
  usePushConnectors,
  useSelectedPushConnector,
} from "../hook/pushConnector";
import { useGoToEditPushConnectorPage } from "../hook/pushConnector/edit";
import { PageContentType, PushConnectorCreated } from "../reducer";
import { useAuth0 } from "@auth0/auth0-react";

export const ToggleSideNavButton: React.FC = () => {
  const toggleSideNavOpen = useToggleSideNavOpen();
  return (
    <Box p={2}>
      <IconButton
        color="primary"
        aria-label="toggle drawer"
        onClick={() => toggleSideNavOpen()}
      >
        <MenuIcon />
      </IconButton>
    </Box>
  );
};

export const SideNavPullConnector: React.FC<{
  connector: any;
}> = ({ connector }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen: any = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  const connectorId = connector["connection-id"];
  const tables = useConnectorTables(connectorId);
  const setSelectedPullConnector = useSetSelectedPullConnector();

  return (
    <>
      <ListItem sx={{ pl: 4 }}>
        <IconButton
          onClick={() => toggleOpen()}
          color="primary"
          edge="start"
          aria-label="open"
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <ListItemText primary={connector?.card["schema"]} />
        <ListItemSecondaryAction>
          <IconButton color="primary" edge="end" aria-label="add">
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isOpen}>
        {tables.map((table: any, index: any) => {
          return (
            <ListItemButton
              key={index}
              sx={{ pl: 8 }}
              onClick={() =>
                setSelectedPullConnector({
                  connectorId,
                  tableId: table["file-id"],
                })
              }
            >
              <ListItemText primary={table.name} />
            </ListItemButton>
          );
        })}
      </Collapse>
    </>
  );
};

export const SideNavPullConnectors: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [connectors, setConnectors] = useConnectors();

  return (
    <>
      <ListItem>
        <IconButton
          onClick={() => toggleOpen()}
          color="primary"
          edge="start"
          aria-label="open"
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <ListItemText primary="Pull Connectors" />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              setSelectedPage({
                contentType: PageContentType.SELECT_CONNECT_CARD_TYPE,
              });
            }}
            color="primary"
            edge="end"
            aria-label="add"
          >
            <AddIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isOpen}>
        <List>
          {connectors.map((connector: any, index: number) => (
            <SideNavPullConnector key={index} connector={connector} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const SideNavPushConnector: React.FC<{
  connector: PushConnectorCreated;
}> = ({ connector }) => {
  const [selectedPushConnector, setSelectedPushConnector] =
    useSelectedPushConnector();
  return (
    <>
      <ListItemButton
        sx={{ pl: 4 }}
        onClick={(e) => {
          setSelectedPushConnector(connector.id);
        }}
      >
        <ListItemText primary={connector.name} />
      </ListItemButton>
    </>
  );
};

export const SideNavPushConnectors: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  const [selectedPage, setSelectedPage] = useSelectedPage();
  const [pushConnectors, setPushConnectors] = usePushConnectors();
  const goToEditPushConnectorPage = useGoToEditPushConnectorPage();

  return (
    <>
      <ListItem>
        <IconButton
          onClick={() => toggleOpen()}
          color="primary"
          edge="start"
          aria-label="open"
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <ListItemText primary="Push Connectors" />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => {
              goToEditPushConnectorPage();
            }}
            color="primary"
            edge="end"
            aria-label="add"
          >
            <AddIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={isOpen}>
        <List>
          {Object.values(pushConnectors).map(
            (connector: any, index: number) => (
              <SideNavPushConnector key={index} connector={connector} />
            )
          )}
        </List>
      </Collapse>
    </>
  );
};

export const SideNavNote: React.FC<{
  page: any;
}> = ({ page }) => {
  const pageData = page?.["page-data"];
  const pageName = pageData?.name;
  const setSelectedNote = useSetSelectedNote();
  return (
    <ListItemButton
      onClick={() => setSelectedNote(page)}
      sx={{ pl: 4 }}
      // selected={selectedPage === page.id}
    >
      <ListItemText primary={pageName} />
    </ListItemButton>
  );
};

export const SideNavNotebook: React.FC<{}> = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen: any = () => {
    setIsOpen((isOpen) => !isOpen);
  };
  const [notebook, setNotebook] = useNotebook();
  const pages = notebook?.pages ?? [];

  return (
    <>
      <ListItem>
        <IconButton
          onClick={() => toggleOpen()}
          color="primary"
          edge="start"
          aria-label="open"
        >
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <ListItemText primary="Notebook" />
      </ListItem>
      <Collapse in={isOpen}>
        <List>
          {pages.map((page: any, index: number) => (
            <SideNavNote key={index} page={page} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export const SideNavLoggedIn: React.FC<{ drawerWidth: string }> = ({
  drawerWidth,
}) => {
  const [sideNavOpen] = useSideNavOpen();

  return (
    <Drawer
      variant="persistent"
      open={sideNavOpen}
      sx={{
        width: sideNavOpen ? drawerWidth : "0",
        "& .MuiDrawer-paper": {
          width: sideNavOpen ? drawerWidth : "0",
          boxSizing: "border-box",
        },
      }}
    >
      {sideNavOpen && <ToggleSideNavButton />}
      <List>
        <SideNavPullConnectors />
        <SideNavPushConnectors />
        <SideNavNotebook />
      </List>
    </Drawer>
  );
};

export const SideNav: React.FC<{ drawerWidth: string }> = ({ drawerWidth }) => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? (
    <SideNavLoggedIn drawerWidth={drawerWidth} />
  ) : (
    <></>
  );
};
